/**
 * Module: Smooth Scroll.
 * Animate page srcolling to simulate "smoothness".
 *
 * @category   JavaScript|Module
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


/**
 * Function to animate the scroll.
 *
 * @param {Object}  anchor
 * @param {Integer} duration
 */
// "use strict";

var smoothScroll = function (anchor, duration) {
    "use strict";

    var startLocation = window.pageYOffset;
    var endLocation = anchor.offsetTop;
    var distance = endLocation - startLocation;
    var increments = distance / (duration / 16);
    var stopAnimation;

    /**
     * Scroll the page by an increment, and check if it's time to stop.
     */
    var animateScroll = function () {
        window.scrollBy(0, increments);

        stopAnimation();
    };


    if /* Down Scroll. */(increments >= 0) {
        /**
         * Stop animation when you reach the anchor or bottom of the page.
         */
        stopAnimation = function () {
            var travelled = window.pageYOffset;
            if ((travelled >= (endLocation - increments)) ||
                ((window.innerHeight + travelled) >= document.body.offsetHeight)
            ) {
                clearInterval(runAnimation);
            }
        };
    } else /* Up Scroll. */{
        /**
         * Stop animation when you reach the anchor or top of the page.
         */
        stopAnimation = function () {
            var travelled = window.pageYOffset;
            if (travelled <= (endLocation || 0)) {
                clearInterval(runAnimation);
            }
        };
    }

    /**
     * Loop the animation function.
     */
    var runAnimation = setInterval(animateScroll, 16);
};


var scrollToggle = document.querySelectorAll(".scroll-top");

/**
 *
 */
[].forEach.call(scrollToggle, function (toggle) {
    toggle.addEventListener("click", function (evt) {
        "use strict";

        evt.preventDefault();

        /**
         * Get anchor link and calculate distance from the top.
         */
        var dataSpeed = toggle.getAttribute("data-speed");
        var dataID = toggle.getAttribute("href");
        var dataTarget = document.querySelector(dataID);
        if (dataTarget) {
            smoothScroll(dataTarget, dataSpeed || 500);
        }
    }, false);
});

/* <> */
