/**
 * Main.
 *
 * @category   JavaScript
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


/**
 * External Links.
 *
 * Open external links in new tab/window.
 * ----------------------------------------------------------------------- *
 */
[].slice.call(
    document.querySelectorAll("a[rel~=external],.footer-nav ul li:last-child a")
).map(function (anchor, index) {
    anchor.setAttribute("target", "_blank");
});


/**
 * ...
 *
 * ...
 * ----------------------------------------------------------------------- *
 */
// [].slice.call(document.querySelectorAll(".page-trail-stops .vendor-card")).map(function (card, index) {
//     card.addEventListener("click", function (evt) {
//         location.href = this.querySelector("a.u-url").getAttribute("href");
//     });
// });


// /**
//  * Off-Canvas Menu.
//  *
//  * ----------------------------------------------------------------------- *
//  */
// var body = document.getElementsByTagName("body")[0];
// [].slice.call(document.querySelectorAll(".menu-trigger")).map(function (menuTrigger, index) {
//     menuTrigger.addEventListener("click", function (evt) {
//         evt.preventDefault();

//         body.classList.toggle("sidemenu");
//     });
// });


// /**
//  * Dropdown Menus.
//  *
//  * ----------------------------------------------------------------------- *
//  */
// var hasTouch = !("ontouchstart" in window);
// var iOS5 = /iPad|iPod|iPhone/.test(navigator.platform) && "matchMedia" in window;
// var dropdowns = document.querySelectorAll("nav.main-menu li.dropdown > a");


// /**
//  * Hook "touch" events to dropdown menus.
//  *
//  * We don't want to run this code on iOS5+
// */
// if (hasTouch && document.querySelectorAll && !iOS5) {
//     var iter, len, element;

//     var menuTouch = function (event) {
//         "use strict";

//         var i, len;
//         var noclick = !(this.dataNoclick);

//         /**
//          * Reset flag on all links.
//          */
//         for (i = 0, len = dropdowns.length; i < len; ++i) {
//             dropdowns[i].dataNoclick = false;
//         }

//         /**
//          * Set new flag value and focus on dropdown menu.
//          */
//         this.dataNoclick = noclick;
//         this.focus();
//     };

//     var menuClick = function (event) {
//         "use strict";

//         /**
//          * If click isn't wanted, prevent it.
//          */
//         if (this.dataNoclick) {
//             event.preventDefault();
//         }
//     };

//     for (iter = 0, len = dropdowns.length; iter < len; ++iter) {
//         element = dropdowns[iter];
//         element.dataNoclick = false;
//         element.addEventListener("touchstart", menuTouch, false);
//         element.addEventListener("click", menuClick, false);
//     }
// }


// [].slice.call(dropdowns).map(function (menu, index) {
//     "use strict";

//     menu.addEventListener("click", function (evt) {
//         evt.preventDefault();
//     });
// });


/**
 * Stylish <select> elements.
 *
 * ----------------------------------------------------------------------- *
 */
[].slice.call(document.querySelectorAll("select.cs-select")).map(function (elem, index) {
    "use strict";

    new SelectFx(elem);
});


/**
 * Tabs.
 *
 * ----------------------------------------------------------------------- *
 */
// var tabs = Array.from(document.querySelectorAll(".tab-handles > .thandle"));
// [].forEach.call(tabs, function (tab, index) {
//     tab.addEventListener("click", function (evt) {
//         tabHandler(tabs, evt);
//     });
// });


/**
 * Sticky, shrinking header.
 *
 * @param {Event} evt
 * ----------------------------------------------------------------------- *
 */
/*window.addEventListener("scroll", function (evt) {
    "use strict";

    var distanceY = window.pageYOffset || document.documentElement.scrollTop;
    var body = document.getElementsByTagName("body")[0];
    var marker = 100;

    if (distanceY > marker) {
        body.classList.add("headshrink");
    } else if (body.classList.contains("headshrink")) {
        body.classList.remove("headshrink");
    }
});*/




/**
 * Custom registration form: We must capture the {user_login} input field
 * and populate it with a sanitized version of a registerer's email address.
 *
 * This allows a new user to register by email alone.
 */
var customRegistrationForm = document.querySelector("#registrator");
if (exists(customRegistrationForm)) {
    var registerEmail = customRegistrationForm.querySelector("#user_email");
    var registerLogin = customRegistrationForm.querySelector("#user_login");

    customRegistrationForm.addEventListener("submit", function (evt) {
        "use strict";

        evt.preventDefault();

        if (registerEmail.value !== '') {
            var username = sanitizeUsername(registerEmail.value);
            registerLogin.setAttribute("value", username);
        }

        this.submit();
    });
}


$(document).ready( function() {

  // FUNCTIONS

    // debounce so filtering doesn't happen every millisecond
    function debounce( fn, threshold ) {
      var timeout;
      return function debounced() {
        if ( timeout ) {
          clearTimeout( timeout );
        }
        function delayed() {
          fn();
          timeout = null;
        }
        setTimeout( delayed, threshold || 100 );
      };
    }

    function round_select2_containers() {
      
      $('.filters__item').each( function() {
        
        requestAnimationFrame( function() {
          $(this).css('width','');
          var my_width = $(this).width();
          $(this).css('width', Math.round(my_width) );
        });

      });

    }

  // EVENTS

    // site maintenance dismissal
    $('body').on('click', '.maintenance a[href="#dismiss"]', function(e) {
      e.preventDefault();

      $('.maintenance').slideUp(200);
      Cookies.set('dismiss_maintenance_message', true, { expires: 2 });

    });

    
    // trail toggle
    $('.identity__toggle').click( function(e) {
      e.preventDefault();

      $('.identity__chooser__container').toggleClass('identity__chooser__container--open');
    });

    // trail reset on mobile menu click
    $('.offcanvas-menu').click( function() {
      $('.identity__chooser__container').removeClass('identity__chooser__container--open');
    });


    // identity menu toggle
    $('.nav__primary__menu__item--toggle').click( function(e) {
      e.preventDefault();
      $('body').toggleClass('sidemenu');
    });

    $('.offcanvas-menu__close').click( function() {
      $('body').removeClass('sidemenu');
    });

    // identity reset on main content press
    $('.site__content').click( function() {
      $('.identity__chooser__container').removeClass('identity__chooser__container--open');
    });

    // mobile menu reset and identity reset on mobile background press
    $('.offcanvas-menu__background').click( function(e) {
      e.preventDefault();

      $('.identity__chooser__container').removeClass('identity__chooser__container--open');
      $('body').removeClass('sidemenu');
    });


    // filter control toggle on mobile
    $('.filters__button').click( function(e) {
      e.preventDefault();

      $('.filter__controls').addClass('filter__controls--open');
      $('.filters').slideDown(200);
    });

    $('.filters__collapse').click( function(e) {
      e.preventDefault();

      $('.filter__controls').removeClass('filter__controls--open');
      $('.filters').slideUp(200);
    });


    // card results updating on search fitler changes

      $('.filters__select, .filters__multiple').on('change', function (e) {

        // console.log('change');

        // flatten object by concatting values
        function concatValues( obj ) {
          var value = '';
          for ( var prop in obj ) {
            value += obj[ prop ];
          }
          return value;
        }
        
        // reset no results in anticipation of matches
        $('.list__no_matches').addClass('list__no_matches--hidden'); 

        var my_filters = {};

        // single-value select option
        /* 
        // var region_value = $('#filter_region').val();
        // if ( region_value !== '*' && region_value !== '') {
        //   my_filters.region = '.region_' + product_value;
        // }
        */

        // multiple-value select option
        var type_value = $('#filter_type').val();

        if ( typeof type_value !== 'undefined' ) {
          var type_filter = '';
          $.each( $(type_value), function(item,value) {
            type_filter += '.type_' + value + ', ';
          });
          type_filter = type_filter.replace(/,\s*$/, "");
          my_filters.type = type_filter;
        }
        // console.log('type_value', type_value);

        var region_value = $('#filter_region').val();
        if ( typeof region_value !== 'undefined' ) {
          var region_filter = '';
          $.each( $(region_value), function(item,value) {
            region_filter += '.region_' + value + ', ';
          });
          region_filter = region_filter.replace(/,\s*$/, "");
          my_filters.region = region_filter;
        }
        // console.log('region_value', region_value);
        

        buttonFilter = concatValues( my_filters );
        // console.log('buttonFilter', buttonFilter);

        if ( typeof listing_isotope !== 'undefined') {
          listing_isotope.isotope();  
        }
        
        
      });



    // inline scroll link
      $('a.button').click( function(e) {

        console.log('click');

        var my_link = $(this);
        var my_href = $(this).attr('href');

        if ( my_href.indexOf('#') >= 0 ) {

          var my_anchor = my_href.replace('#','');
          var my_destination = $('.page__section[data-anchor="' + my_anchor + '"]');

          if ( my_destination.length > 0 ) {

            e.preventDefault();

            // var my_offset = $('.site-header').height() * -1;
            var my_offset = 0;

            $.scrollTo( my_destination, 400, {
                offset: my_offset
              }
            );

            my_link.blur();

          }
          
        }

      });

    // reset search value on standardized psuedo dom replacement
      $('.filters__search__reset').click( function() {
        $('.filters__search__item').addClass('filters__search__item--empty');
        $('.filters__search__field').val('');

        qsRegex = new RegExp( $quicksearch.val(), 'gi' );
        listing_isotope.isotope();
      });


    // isotope search filtering update      
      var $quicksearch = $('.filters__search__field').keyup( debounce( function() {
        qsRegex = new RegExp( $quicksearch.val(), 'gi' );

        if ( $quicksearch.val() !== '' ) {
          $('.filters__search__item').removeClass('filters__search__item--empty');
        } else {
          $('.filters__search__item').addClass('filters__search__item--empty');
        }

        listing_isotope.isotope();
      }) );


    // stamp count hover
    $('.passport__summary__collected a').hover( 
      function() {
        $(this).parents('.passport__summary__collected').addClass('passport__summary__collected--hovering');
      },
      function() {
        $(this).parents('.passport__summary__collected').removeClass('passport__summary__collected--hovering');
      }
    );

    // newsletter modal
    $('a[href^="#newsletter"]').click( function(e) {
      e.preventDefault();

      $.fancybox.open([
          {
            src: '.newsletter__container',
            opts : {
              
            }
          }
        ], {
        
        type: 'inline',

        baseClass : 'newsletter__fancybox',

        touch : false,
        arrows: false,
        keyboard: false,
        infobar : false,
        toolbar : false,

        modal : false,


        afterClose: function() {
          $('.newsletter__container').removeClass('newsletter__container--complete');
        },

      });

    });

    

    $('.page-submit-form .contact__form__actions .wpcf7-submit').on('click', function() {

      if ( $('input[name="attachment1"]').val() !== '' ) {
        $(this).val('Uploading images…');  
      }

    });
    

    // newsletter confirmation behaviours
    $(document).on('wpcf7mailsent', function (e) {

        // console.log('form data', e);
        
        var form_instance = e.target;

        if ( $(form_instance).find('.contact__form__meta').length > 0 ) {
         
          var form_type = $(form_instance).find('.contact__form__meta').data('form-type');

          if ( form_type === 'newsletter') {
            $('.newsletter__container').addClass('newsletter__container--complete');
          }
          
        }
        
      });


  // INIT

    // image focal points
      $('.focuspoint').focusPoint().addClass('focuspoint--loaded');

    // filter dropddowns
      var multiple_selection = $('.filters__multiple');

      if ( multiple_selection.length > 0 ) {
        round_select2_containers();
      }

      multiple_selection_select2 = multiple_selection.select2({
        width: '100%',
      });

      multiple_selection_select2.on('select2:opening select2:closing', function( event ) {
        var $searchfield = $(this).parent().find('.select2-search__field');
        $searchfield.prop('disabled', true);
      });

      multiple_selection.on('change', function() {
        
        var $selected = $(this).find('option:selected');
        var $container = $('.filters__results--' + $(this).data('taxonomy') );

        var search_concat = '';
        
        var $list = $('<ul class="filters__results__list  group">');
        $selected.each(function(k, v) {
          var $li = $('<li class="filters__results__list__item  tag-selected"><a class="filters__results__list__remove">×</a>' + $(v).text() + '</li>');
          
          $li.children('a.filters__results__list__remove')
            .off('click.select2-copy')
            .on('click.select2-copy', function(e) {
              var $opt = $(this).data('select2-opt');
              $opt.attr('selected', false);
              $opt.parents('select').trigger('change');
            }).data('select2-opt', $(v));

          $list.append($li);

          search_concat += $(v).text() + ', ';

        });

        $container.html('').append($list);

        search_concat = search_concat.replace(/,\s*$/, "");

      });


    // listing results

      listing_results = $('.list__results');

      if ( listing_results.length > 0 ) {

        var qsRegex;
        var buttonFilter;

          var listing_isotope = listing_results.isotope({
            percentPosition: true,
            itemSelector: '.list__results__item',
            layoutMode: 'packery',
            packery: {
              columnWidth: '.list__results__sizer',
              gutter: '.list__results__gutter',
            },

            filter : function() {
              var $this = $(this);
              var searchResult = qsRegex ? $this.text().match( qsRegex ) : true;
              var buttonResult = buttonFilter ? $this.is( buttonFilter ) : true;
              return searchResult && buttonResult;
            },

            isInitLayout: false,

          });

          listing_isotope.on( 'layoutComplete', function( event, laidOutItems ) {
            
            $('.lazy').lazyload();

            var resultCount = laidOutItems.length;
            if( resultCount === 0) {
              $('.list__no_matches').removeClass('list__no_matches--hidden');
            }

          });

          listing_isotope.on( 'arrangeComplete', function( event, filteredItems ) {
            
            $('.lazy').lazyload();

          });

          listing_isotope.isotope('layout');

        // });

      }
    

    // matchHeight rules
      $('.page__section__group__item').matchHeight();
      $('.prize__list h4').matchHeight();
      // $('.experiences__list__meta__copy').matchHeight();


    // contact form optgroup support
      $(function(){
        var foundin = $('.contact__form__select option:contains("optgroup-")');
        $.each(foundin, function(value){
          var updated = $(this).val().replace('optgroup-','');
          $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'"></optgroup>');
        });
        $('.contact__form__select option:contains("optgroup-")').remove();
        $('.contact__form__select option:contains("endoptgroup")').remove();
      });

    // hash navigation check
      if( window.location.hash ) {
        
        var my_anchor = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

        var my_destination = $('.page__section[data-anchor="' + my_anchor + '"]');
        if ( my_destination.length >= 0 ) {

          setTimeout( function() {
          
            // var my_offset = $('.site__header').height() * -1;
            var my_offset = 0;

            $.scrollTo( my_destination, 400, {
                offset: my_offset
              }
            );

          }, 200 );
        }

        // test for trail stops
        if ( my_anchor === 'beer' ||  my_anchor === 'biere' || my_anchor === 'cider' || my_anchor === 'cidre' || my_anchor === 'spirits' || my_anchor === 'spiritueux' || my_anchor === 'wine' || my_anchor === 'vin' || my_anchor === 'mead' || my_anchor === 'hydromel' ) {
          
          var my_term_ids = [];

          if ( my_anchor === 'beer' ) {
            my_term_ids.push(31);
            my_term_ids.push(32);
          }
          if ( my_anchor === 'biere' ) {
            my_term_ids.push(56);
            my_term_ids.push(64);
          }

          if ( my_anchor === 'cider' ) {
            my_term_ids.push(33);
          }
          if ( my_anchor === 'cidre' ) {
            my_term_ids.push(63);
          }
          
          if ( my_anchor === 'mead' ) {
            my_term_ids.push(50);
          }
          if ( my_anchor === 'hydromel' ) {
            my_term_ids.push(65);
          }

          if ( my_anchor === 'spirits' ) {
            my_term_ids.push(34);
          }
          if ( my_anchor === 'spiritueux' ) {
            my_term_ids.push(57);
          }

          if ( my_anchor === 'wine' ) {
            my_term_ids.push(39);
          }
          if ( my_anchor === 'vin' ) {
            my_term_ids.push(58);
          }


          // console.log('my_term_ids', my_term_ids);
          var my_select = $('#filter_type')[0];

          $(my_term_ids).each( function( item, value ) {
            
            for (var i = 0; i < my_select.length; i++) {
              if(my_select.options[i].value == value) {
                my_select.options[i].selected = true;
              } else {
                // my_select.options[i].selected = false;
              }
            }

          });

          $('#filter_type').trigger('change');
          // console.log( 'my_select', my_select.val() );

        }

      }


    // custom contact form file upload UI

      $('.contact__form__fields').find('input[type=file]').each( function() {

        var field = $(this);
        field.customFile();
        
        field.on('change', function() {
          var field_contents = $(this).val();
          
          var my_container = $(field).parents('.wpcf7-form-control-wrap');

          // console.log('my_container',my_container);

          my_container.nextAll('.wpcf7-form-control-wrap').first().find('.file-upload-wrapper').addClass('file-upload-wrapper--visible');

        });

      });



    // get maintenance message in a page cache friendly way
      if ( typeof Cookies.get('dismiss_maintenance_message') === 'undefined' ) {

          $.ajax({
          url: ajax_url,
          data: {
            'action': 'show-maintenance-message',
          },
          complete: function(data) {
            
            var maintenance_message = data.responseText;

            if ( maintenance_message !== '') {
              $(maintenance_message).insertBefore('.identity__chooser__container');
            }

          },

        });

      }

});

/* <> */

