/**
 * Module: Classie.
 * Basic class management.
 *
 * @category   JavaScript|Module
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


/*jshint browser: true, strict: true, undef: true */
/*global define: false */

(function (window) {
    "use strict";

    /**
     * Class helper functions from bonzo
     *
     * @see https://github.com/ded/bonzo
     */

    function classReg(className)
    {
        return new RegExp("(^|\\s+)"+ className +"(\\s+|$)");
    }

    /**
     * {classList} support for class management.
     */
    var hasClass, addClass, removeClass;

    if ("classList" in document.documentElement) {
        hasClass = function (elem, c) {
            return elem.classList.contains(c);
        };

        addClass = function (elem, c) {
            elem.classList.add(c);
        };

        removeClass = function (elem, c) {
            elem.classList.remove(c);
        };
    } else {
        hasClass = function (elem, c) {
            return classReg(c).test(elem.className);
        };

        addClass = function (elem, c) {
            if (!hasClass(elem, c)) {
                elem.className = elem.className +" "+ c;
            }
        };

        removeClass = function (elem, c) {
            elem.className = elem.className.replace(classReg(c), " ");
        };
    }


    function toggleClass(elem, c)
    {
        var fn = hasClass(elem, c) ? removeClass : addClass;
        fn(elem, c);
    }

    var classie = {
        /**
         * Full names.
         */
        hasClass: hasClass,
        addClass: addClass,
        removeClass: removeClass,
        toggleClass: toggleClass,
        /**
         * Short names.
         */
        has: hasClass,
        add: addClass,
        remove: removeClass,
        toggle: toggleClass
    };

    /**
     * Transport.
     */
    if (typeof define === "function" && define.amd) {
        /* AMD. */
        define(classie);
    } else {
        /* Browser global. */
        window.classie = classie;
    }
})(window);

/* <> */
