/**
 * GeoLocation.
 *
 * @category   JavaScript
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


var pReq;
var userGeoData;
var localVendors = [];

/**
 * GeoLocation Query Parameters.
 */
var geoLocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};


/**
 * ...
 *
 * @param {Object} pos
 */
var geoLocationSuccess = function (pos) {
    "use strict";

    userGeoData = {
        timestamp: Date.now(),
        accuracy: pos.coords.accuracy,
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
    };

    localStorage.setItem("userGeoDataStorage", JSON.stringify(userGeoData));
};


/**
 * ...
 *
 * @param {Object}
 */
var geoLocationError = function (err) {
    "use strict";

    console.warn("ERROR:"+ err.code +":"+ err.message);
};


/**
 * ...
 *
 * @param {String} geoTimestamp
 *
 * @returns {String}
 */
var outdatedTimestamp = function (geoTimestamp) {
    "use strict";

    // return ((new Date()) - geoTimestamp) >= 60 * 60 * 1000; /* 1 Hour. */
    return ((new Date()) - geoTimestamp) >= 2 * 60 * 1000; /* 2 minutes. */
};


/**
 * Populate our {localVendors} object from the database.
 *
 * @returns {}
 */
var processLocalVendors = function () {
    "use strict";

    if (pReq.readyState === XMLHttpRequest.DONE) {
        if (pReq.status === 200) {
            localVendors = JSON.parse(pReq.responseText);

            /* Count 'em up. */
            // console.log("Local Vendors: ", localVendors);
        } else {
            // console.log("There was a problem querying local vendors from the database.");
        }
    }
};


/**
 * ...
 *
 * @param {Object} geoData
 */
var getLocalVendors = function (geoData) {
    "use strict";

    pReq = new XMLHttpRequest();
    if (!pReq) {
        alert("Giving up :(\nCannot create an XMLHTTP instance.");

        return false;
    }

    pReq.open("POST", ct.ajax +"?action=vendorProximity", true);
    pReq.setRequestHeader("Content-type", "application/json");
    pReq.onreadystatechange = processLocalVendors;
    pReq.send(JSON.stringify(geoData));
};


/**
 * ...
 */
var getUserGeoData = function () {
    "use strict";

    function prepUserGeo()
    {
        if ((host === "culinary-trails.dev") || !navigator.geolocation) {
            console.log("Host:", host);

            if (!navigator.geolocation) {
                console.log("Your browser doesn't support the GeoLocation API");
            }

            var time = Date.now();
            if (host === "culinary-trails.dev") {
                /**
                 * TESTING TESTING TESTING
                 * ----------------------------------------------------------- *
                 */
                userGeoData = {
                    timestamp: time,
                    accuracy: '',
                    latitude: '',
                    longitude: ''


                    /**
                     * Simulated locations.
                     * ------------------------------------------------------- *
                     */

                    /** -- [ JASON'S HOME ] */
                    // accuracy: 10000,
                    // latitude: 44.6326118,
                    // longitude: -63.627043

                    /** -- [ DOWNTOWN (BARRINGTON+DUKE) ] */
                    // accuracy: 10000,
                    // latitude: 44.649272,
                    // longitude: -63.574946

                    /** -- [ GAHAN HOUSE HARBOURFRONT ] */
                    // accuracy: 10000,
                    // latitude: 44.6516151,
                    // longitude: -63.5796111

                    /** -- [ WEIRD HARBOUR ESPRESSO BAR ] */
                    // accuracy: 10000,
                    // latitude: 44.64667,
                    // longitude: -63.574148

                    /** -- [ 2 CROWS BREWING CO ] */
                    // accuracy: 10000,
                    // latitude: 44.6512045,
                    // longitude: -63.5792112

                    /** -- [ HALIFAX ARMY NAVY STORE ] */
                    // accuracy: 10000,
                    // latitude: 44.656428,
                    // longitude: -63.595134
                };
            } else {
                userGeoData = {
                    timestamp: time,
                    accuracy: 10000,
                    latitude: '',
                    longitude: ''
                };
            }

            localStorage.setItem("userGeoDataStorage", JSON.stringify(userGeoData));
            console.log("Updating GeoLocation data.");
        } else {
            /**
             * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
             */
            navigator.geolocation.getCurrentPosition(geoLocationSuccess, geoLocationError, geoLocationOptions);
        }
    }

    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
     */
    if (localStorage.getItem("userGeoDataStorage")) {
        userGeoData = JSON.parse(localStorage.getItem("userGeoDataStorage"));

        if (outdatedTimestamp(userGeoData.timestamp)) {
            prepUserGeo();
        }
    } else {
        prepUserGeo();
    }

    /*console.log("User Geo Data", userGeoData);*/
};

var checkInGeoLocation = function (geoData) {
    "use strict";

    var uAcc = document.querySelector("#userLocAccuracy");
    var uLat = document.querySelector("#userLocLatitude");
    var uLng = document.querySelector("#userLocLongitude");
    
    if (uAcc === null || uLat === null || uLng === null) {
        return false;
    }
    /**
     * GeoLocation API data.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation
     */
    uAcc.value = geoData.accuracy;
    uLat.value = geoData.latitude;
    uLng.value = geoData.longitude;
};

/* -- */


getUserGeoData();
getLocalVendors(userGeoData);
checkInGeoLocation(userGeoData);
/* <> */
