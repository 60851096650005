/**
 * Vendor Profile.
 *
 * @category   JavaScript
 * @subpackage CulinaryTrailsAssetsManager
 * @author     Jason D. Moss <jason@jdmlabs.com>
 * @copyright  2017 Taste of Nova Scotia. All rights reserved.
 * @link       https://novascotiaculinarytrails.com/
 */


var infowindow;
var map;
var marker;


/**
 * Render map for individual vendor.
 *
 * @returns {}
 */
function vendorMapping()
{
    "use strict";

    var latlng = new google.maps.LatLng(Vendor.Coords.latitude,Vendor.Coords.longitude);
    map = new google.maps.Map(document.getElementById("vendorMap"), {
        zoom: 18,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false
    });

    infowindow = new google.maps.InfoWindow();

    /**
     * If we have a {placeId} for the vendor, use that for coordinates.
     */
    if (exists(Vendor.Coords.placeId)) {
        var request = {
            placeId: Vendor.Coords.placeId
        };

        var service = new google.maps.places.PlacesService(map);
        service.getDetails(request, placesServiceCallback);
    } else {
        /**
         * Use the proveded latitude and longitude.
         */
        marker = new google.maps.Marker({
            map: map,
            draggable: true,
            animation: google.maps.Animation.DROP,
            position: latlng
        });
    }
}


/**
 * ...
 *
 * @param {} place
 *
 * @returns {}
 */
function createPlaceMarker(place)
{
    "use strict";

    var placeLoc = place.geometry.location;
    var marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location
    });

    google.maps.event.addListener(marker, "click", function() {
        infowindow.setContent(place.name);
        infowindow.open(map, this);
    });
}


/**
 * ...
 *
 * @param {} place
 * @param {} status
 *
 * @returns {}
 */
function placesServiceCallback(place, status)
{
    "use strict";

    if (status == google.maps.places.PlacesServiceStatus.OK) {
        createPlaceMarker(place);
    }
}

/* <> */
